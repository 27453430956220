<template>
  <el-dialog :title="currentIndex === -1?'添加产品':'编辑产品'" :visible.sync="openDialog" width="500px"
    :show-close="true" class="dialog-vertical" @close="cancel('productForm')">
    <el-form class="dialog-form" size="medium" label-width="85px" :model="productForm" :rules="rules"
      ref="productForm">
      <el-form-item prop="product_name" label="产品名称：">
        <el-input type="text" v-model="productForm.product_name" placeholder="请输入产品名称"></el-input>
      </el-form-item>
      <el-form-item prop="tags" label="产品标签：">
        <div>
          <el-tag v-for="(tag, index) in productForm.tags" :key="index" closable @close="CloseTag(index)">
            {{ tag }}
          </el-tag>
          <el-input class="input-new-tag" v-if="!showBtn" size="small" ref="tagInput" maxlength="15"
            show-word-limit v-model="inputValue" @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm">
          </el-input>
          <el-button class="tag-btn" @click="AddTag" v-if="showBtn && productForm.tags.length < 3">+ 添加
          </el-button>
        </div>
      </el-form-item>
      <el-form-item prop="description" label="产品介绍：">
        <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" v-model="productForm.description"
          placeholder="请输入产品介绍">
        </el-input>
      </el-form-item>
      <el-form-item prop="image" label="产品图片：">
        <image-wall ratio="96:96" v-model="productForm.image" :width="96" :height="96" addText="上传图片">
          <p slot="info" class="info-title">推荐尺寸 96*96</p>
        </image-wall>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('productForm')">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { noChecked } from "../../api/examine/examine-list";
import ImageWall from "@/modules/common/components/SingleMediaWall.vue";
import { uuid } from "@/base/utils/tool.js";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    productList: {
      type: [Array, Object],
    },
    currentIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      rules: {
        product_name: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
        ],
        tags: [
          {
            required: true,
            validator: this.tagsPass,
            trigger: "blur",
          },
        ],
        description: [
          { required: true, message: "请输入产品介绍", trigger: "blur" },
        ],
      },
      productForm: {
        ident: 0, // 唯一标识
        product_name: "",
        tags: [],
        description: "",
        image: "",
      },
      showBtn: true,
      inputValue: "",
    };
  },
  methods: {
    // 添加tag
    AddTag(key) {
      this.showBtn = false;
      this.$nextTick((_) => {
        setTimeout(() => {
          this.$refs.tagInput.$refs.input.focus();
        }, 100);
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      this.productForm.tags.push(inputValue);
      this.inputValue = "";
      this.showBtn = true;
    },
    // 删除tag
    CloseTag(i) {
      this.productForm.tags.splice(i, 1);
    },
    // 验证内容
    tagsPass(rule, value, callback) {
      if (value.length) {
        callback();
      } else {
        callback(new Error("请添加产品标签"));
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.currentIndex === -1) {
            this.productForm.ident = uuid(5);
            this.products.push({ ...this.productForm });
            this.$refs[formName].resetFields();
          } else {
            this.products.splice(this.currentIndex, 1, { ...this.productForm });
          }
          this.openDialog = false;
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
  },
  watch: {
    currentIndex(val) {
      if (val !== -1) {
        this.productForm = this.products[val];
      } else {
        this.productForm = {
          ident: 0, // 唯一标识
          product_name: "",
          tags: [],
          description: "",
          image: "",
        };
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    products: {
      get() {
        return this.productList;
      },
      set(val) {
        this.$emit("update: changeList", val);
      },
    },
  },
  components: {
    ImageWall,
  },
};
</script>

<style lang="scss" scoped>
.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}

.input-new-tag {
  width: 150px;
}

.tag-btn {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.dialog-form {
  .el-form-item {
    margin-bottom: 22px;
  }
  ::v-deep.el-form-item__label {
    float: left !important;
  }
  ::v-deep.el-form-item__content {
    width: 70%;
  }
}

.info-title {
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 30px;
}
</style>

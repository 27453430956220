<template>
  <div class="confirm-content">
    <el-table :data="memberList" class="thead-light" stripe style="width: 100%">
      <!-- 序号 -->
      <el-table-column width="100" type="index" label="序号" />
      <!-- 操作 -->
      <el-table-column :width="120" fixed="right" label="操作" v-if="!disabled">
        <template slot-scope="scope">
          <el-button :disabled="disabled || scope.row.delete_enable === 0" @click="delRow(scope.$index)" size="small" type="text">移除</el-button>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column :prop="th.prop" show-overflow-tooltip :min-width="th.minWidth || ''"
          :sortable="th.sortable" :key="index" :label="th.label">
          <!-- <template slot="header" slot-scope="scope">
            <span v-if="th.prop === 'position'"><span style="color: red">*</span>{{th.label}}</span>
            <span v-else>{{th.label}}</span>
          </template> -->
          <template slot-scope="scope">
            <!-- 工作职务 -->
            <PopoverInputEdit :required="false" v-if="th.prop === 'position' && !disabled" v-model="scope.row[th.prop]"
              @confirm="inputChange">
              <span
                :style="scope.row[th.prop] !==''?'color: #606266':'color: #c0c4cc'">{{scope.row[th.prop] || '请输入工作职务'}}</span>
            </PopoverInputEdit>
            <!-- 设为管理员 -->
            <el-checkbox v-else-if="th.prop === 'is_admin'" v-model="scope.row[th.prop]" :true-label="1"
              :disabled="disabled" :false-label="0" @change="handleSetAdmin" />
            <!-- 普通 -->
            <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-button :disabled="disabled" @click="AddMember" class="bottom-btn opacity-btn" v-if="!disabled">+ 关联会员
    </el-button>
    <SelectMember v-model="openSelectMember" :Members="memberList" @changeSelect="handleSelect" />
  </div>
</template>

<script>
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import SelectMember from "@/modules/common/components/MemberSelector";
import { CompanyMembers } from "../../api/company/company-detail";

export default {
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
    getMembers: {
      type: Function,
    },
    ManageUser: {
      type: Object,
    },
    isCompanyAuth: {
      type: Boolean,
      default: true,
    },
    isUnit: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      thead: [
        { label: "会员名称", prop: "nickname", minWidth: 100 },
        { label: "会内职务", prop: "title_name", minWidth: 150 },
        { label: "工作职务", prop: "position", minWidth: 150 },
        { label: "设为管理员", prop: "is_admin", minWidth: 100 },
      ],
      memberList: [],
      openSelectMember: false,
    };
  },
  methods: {
    handleSetAdmin() {
      // if (!this.isCompanyAuth) {
      //   const infoText = '设为管理员需要先上传企业认证的营业执照'
      //   this.$message.info(infoText)
      //   this.memberList.forEach((el) => {
      //     el.is_admin = 0;
        // });
      // }
    },
    // 获取企业员工列表
    getCompanyMembers() {
      const params = {
        id: this.id
      }
      if (typeof this.id === 'string') {
        params.is_unit = 1
      }
      CompanyMembers({ ...params })
        .then((res) => {
          this.memberList = res.data.map(el => ({
            ...el,
            position: el.position || ''
          }));
          this.getMembers(this.memberList);
        })
        .catch((err) => {});
    },
    // 点击添加
    AddMember() {
      this.openSelectMember = true;
    },
    // 点击移除
    delRow(i) {
      this.$msgbox
        .confirm("确定要移除该数据吗？", "提示", {
          type: "error",
        })
        .then(() => {
          this.memberList.splice(i, 1);
          this.getMembers(this.memberList);
        })
        .catch(() => {});
    },
    // 输入职务
    inputChange() {
      this.getMembers(this.memberList);
    },
    // 刷新会员列表
    handleSelect(value) {
      value.forEach((item) => {
        if (item.position === undefined) {
          this.memberList.push(
            Object.assign({}, item, { position: "", is_admin: 0 })
          );
        }
      });
      this.getMembers(this.memberList);
    },
  },
  created() {
    if (this.id) {
      this.getCompanyMembers();
    } else {
      console.log('用户 id 不存在')
    }
  },
  watch: {
    isCompanyAuth(val) {
      if (!val && this.memberList.length) {
        this.memberList.forEach((el) => {
          el.is_admin = 0;
        });
      }
    },
    manage_user: {
      deep: true,
      handler(val) {
        let memberIndex = this.memberList.findIndex(
          (item) => item.user_id === val.user_id
        );
        if (JSON.stringify(val) !== "{}") {
          if (memberIndex > -1) {
            this.memberList.splice(memberIndex, 1, val);
          } else {
            this.memberList.push(val);
          }
        }
      },
    },
  },
  computed: {
    manage_user() {
      return this.ManageUser;
    },
  },
  components: {
    SelectMember,
    PopoverInputEdit,
  },
};
</script>

<style lang="scss" scoped>
.bottom-btn {
  width: 100%;
  margin-top: 8px;
  border: 1px dashed rgba(217, 217, 217, 1);
  font-size: 16px;
  padding: 8px 0;
}

.bottom-btn:not(.is-disabled):hover {
  border: 1px dashed #3576ff;
}
</style>

<template>
  <el-dialog :title="currentIndex === -1?'添加历程':'编辑历程'" :visible.sync="openDialog" width="500px"
    :show-close="true" class="dialog-vertical" @close="cancel('historyForm')">
    <el-form class="dialog-form" size="medium" label-width="85px" :model="historyForm" :rules="rules"
      ref="historyForm">
      <el-form-item prop="description" label="历程描述：">
        <el-input type="textarea" :rows="6" :maxlength="500" show-word-limit v-model="historyForm.description"
          placeholder="请输入历程描述">
        </el-input>
      </el-form-item>
      <el-form-item prop="time" label="历程时间：">
        <el-date-picker style="width: 100%" v-model="historyForm.time" type="year" value-format="yyyy"
          placeholder="请选择年份">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('historyForm')">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { noChecked } from "../../api/examine/examine-list";
import ImageWall from "@/modules/common/components/SingleMediaWall.vue";
import { uuid } from "@/base/utils/tool.js";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    HistoryList: {
      type: [Array, Object],
    },
    currentIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      rules: {
        description: [
          { required: true, message: "请输入历程描述", trigger: "blur" },
        ],
        time: [
          { required: true, message: "请选择历程时间", trigger: "change" },
        ],
      },
      historyForm: {
        ident: 0, // 唯一标识
        description: "",
        time: "",
      },
      showBtn: true,
      inputValue: "",
    };
  },
  methods: {
    // 添加tag
    AddTag(key) {
      this.showBtn = false;
      this.$nextTick((_) => {
        setTimeout(() => {
          this.$refs.tagInput.$refs.input.focus();
        }, 100);
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      this.historyForm.tags.push(inputValue);
      this.inputValue = "";
      this.showBtn = true;
    },
    // 删除tag
    CloseTag(i) {
      this.historyForm.tags.splice(i, 1);
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.currentIndex === -1) {
            this.historyForm.ident = uuid(5);
            this.histories.push({ ...this.historyForm });
            // this.$refs[formName].resetFields();
          } else {
            this.histories.splice(this.currentIndex, 1, {
              ...this.historyForm,
            });
          }
          this.openDialog = false;
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  watch: {
    currentIndex(val) {
      if (val !== -1) {
        this.historyForm = this.histories[val];
      } else {
        this.historyForm = {
          ident: 0, // 唯一标识
          description: "",
          time: "",
        };
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    histories: {
      get() {
        return this.HistoryList;
      },
      set(val) {
        this.$emit("update: changeList", val);
      },
    },
  },
  components: {
    ImageWall,
  },
};
</script>

<style lang="scss" scoped>
.dialog-form {
  .el-form-item {
    margin-bottom: 22px;
  }
  ::v-deep.el-form-item__label {
    float: left !important;
  }
  ::v-deep.el-form-item__content {
    width: 70%;
  }
}
</style>

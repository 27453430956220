<template>
  <div class="confirm-content">
    <el-table :data="HistoryList" class="thead-light" stripe style="width: 100%" id="sort-table"
      row-key="ident">
      <!-- 拖拽排序 -->
      <el-table-column :width="100" label="拖拽排序" v-if="!disabled">
        <span class="el-icon-sort"></span>
      </el-table-column>
      <!-- 序号 -->
      <el-table-column width="100" type="index" :index="indexMethod" label="序号"></el-table-column>
      <!-- 操作 -->
      <el-table-column :width="120" fixed="right" label="操作" v-if="!disabled">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="EditRow(scope.$index)">编辑</el-button>
          <el-button type="text" size="small" @click="DeleteRow(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
          :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
          <template slot-scope="scope">
            <!-- 普通 -->
            <span>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-button :disabled="disabled" @click="AddProduct" class="bottom-btn opacity-btn" v-if="!disabled">+ 添加
    </el-button>
    <HistoryDialog v-model="openHistoryDialog" :HistoryList="HistoryList" :currentIndex="currentIndex"
      :changeList="HistoryList" />
  </div>
</template>

<script>
import HistoryDialog from "./HistoryDialog.vue";
import Sortable from "@/modules/page-renovation/js/Sortable.min.js";
export default {
  model: {
    prop: "Histories",
    event: "updateHistories",
  },
  props: {
    Histories: {
      type: [Array, Object],
      default: () => [],
    },
    disabled: Boolean
  },
  data() {
    return {
      thead: [
        { label: "历程描述", prop: "description", minWidth: 200 },
        { label: "历程时间", prop: "time", minWidth: 100 },
      ],
      currentIndex: -1,
      openHistoryDialog: false,
    };
  },
  methods: {
    // 拖拽排序
    drag() {
      const el = document.querySelectorAll(
        "#sort-table .el-table__body > tbody"
      )[0];
      Sortable.create(el, {
        disabled: false,
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {},
        onEnd: (e) => {
          this.$nextTick(() => {
            let arr = [...this.HistoryList];
            arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理
            this.HistoryList = arr;
          });
        },
      });
    },
    // 点击添加
    AddProduct() {
      this.currentIndex = -1;
      this.openHistoryDialog = true;
    },
    // 编辑
    EditRow(i) {
      this.currentIndex = i;
      this.openHistoryDialog = true;
    },
    // 点击移除
    DeleteRow(i) {
      this.HistoryList.splice(i, 1);
    },
    // 序号计算
    indexMethod(index) {
      return index + 1;
    },
  },
  mounted() {
    this.drag();
  },
  computed: {
    HistoryList: {
      get() {
        return this.Histories || [];
      },
      set(val) {
        this.$emit("updateHistories", val);
      },
    },
  },
  components: {
    HistoryDialog,
  },
};
</script>

<style lang="scss" scoped>
.bottom-btn {
  width: 100%;
  margin-top: 8px;
  border: 1px dashed rgba(217, 217, 217, 1);
  font-size: 16px;
  padding: 8px 0;
}

.bottom-btn:hover {
  border: 1px dashed #3576ff;
}
</style>

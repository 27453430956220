<template>
  <div class="confirm-content">
    <el-table :data="productList" class="thead-light" stripe style="width: 100%" id="sortTable"
      row-key="ident">
      <!-- 拖拽排序 -->
      <el-table-column :width="100" label="拖拽排序" v-if="!disabled">
        <span class="el-icon-sort my-handle cursor-pointer"></span>
      </el-table-column>
      <!-- 序号 -->
      <el-table-column width="100" type="index" :index="indexMethod" label="序号"></el-table-column>
      <!-- 操作 -->
      <el-table-column :width="120" fixed="right" label="操作" v-if="!disabled">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="EditRow(scope.$index)">编辑</el-button>
          <el-button type="text" size="small" @click="DeleteRow(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
          :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
          <template slot-scope="scope">
            <!-- 产品图片 -->
            <el-image class="list-image" v-if="th.prop === 'image'" :src="fixImageUrl(scope.row[th.prop])"
              @click="$previewImage([scope.row[th.prop]])">
            </el-image>
            <!-- 产品标签 -->
            <span v-else-if="th.prop === 'tags'">{{scope.row[th.prop].join(" | ")}}</span>
            <!-- 普通 -->
            <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-button :disabled="disabled" @click="AddProduct" class="bottom-btn opacity-btn" v-if="!disabled">+ 添加
    </el-button>
    <ProductionDialog v-model="openProductDialog" :productList="productList" :currentIndex="currentIndex"
      :changeList="productList" />
  </div>
</template>

<script>
import ProductionDialog from "./ProductionDialog.vue";
import Sortable from "@/modules/page-renovation/js/Sortable.min.js";
export default {
  model: {
    prop: "Productions",
    event: "updateProductions",
  },
  props: {
    Productions: {
      type: [Array, Object],
      default: () => [],
    },
    disabled: Boolean
  },
  data() {
    return {
      thead: [
        { label: "产品名称", prop: "product_name", minWidth: 100 },
        { label: "产品标签", prop: "tags", minWidth: 150 },
        { label: "产品介绍", prop: "description", minWidth: 150 },
        { label: "产品图片", prop: "image", minWidth: 100 },
      ],
      currentIndex: -1,
      openProductDialog: false,
    };
  },
  methods: {
    // 拖拽排序
    drag() {
      const el = document.querySelectorAll(
        "#sortTable .el-table__body > tbody"
      )[0];
      Sortable.create(el, {
        disabled: false,
        // handle: ".my-handle",
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {},
        onEnd: (e) => {
          this.$nextTick(() => {
            let arr = [...this.productList];
            arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理
            this.productList = arr;
          });
        },
      });
    },
    // 点击添加
    AddProduct() {
      this.currentIndex = -1;
      this.openProductDialog = true;
    },
    // 编辑
    EditRow(i) {
      this.currentIndex = i;
      this.openProductDialog = true;
    },
    // 点击移除
    DeleteRow(i) {
      this.productList.splice(i, 1);
    },
    // 序号计算
    indexMethod(index) {
      return index + 1;
    },
  },
  mounted() {
    this.drag();
  },
  computed: {
    productList: {
      get() {
        return this.Productions || []
      },
      set(val) {
        this.$emit("updateProductions", val);
      },
    },
  },
  components: {
    ProductionDialog,
  },
};
</script>

<style lang="scss" scoped>
.bottom-btn {
  width: 100%;
  margin-top: 8px;
  border: 1px dashed rgba(217, 217, 217, 1);
  font-size: 16px;
  padding: 8px 0;
}

.bottom-btn:not(.is-disabled):hover {
  border: 1px dashed #3576ff;
}

.list-image {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
</style>

import api from '@/base/utils/request';

export const unitValidate = data => {
  return api({
    url: '/admin/cyc/user_op/validateUnit',
    method: 'post',
    data
  })
}

export const saveUnitData = data => {
  return api({
    url: '/admin/cyc/user_op/saveUnit',
    method: "post",
    data
  })
}
